
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    @apply mx-auto   flex flex-col-reverse lg:flex-row justify-between items-center px-4;
    margin: 50px auto 66px auto;

    @include md {
        margin: 80px auto 96px auto;
    }
    h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        color: $hairstraightener-main-color;

        @include lg {
            font-size: 25px;
            line-height: 37px;
        }
    }
    span {
        width: 70px;
        height: 2px;
        background-color: $hairstraightener-span-color;
        display: block;
    }
    p:nth-of-type(1) {
        margin-top: 25px;
    }
    p {
        font-size: 14px;
        line-height: 27px;
        color: $hairstraightener-paragraph-color;

        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
    ul {
        font-size: 14px;
        line-height: 27px;
        color: $hairstraightener-paragraph-color;
        list-style-type: disc;
        margin: 10px 0;

        margin-left: 25px;
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
